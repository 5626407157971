package io.github.shaksternano.albert21.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.AnimationTimingFunction
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.s
import org.w3c.dom.Audio

@Composable
fun Fluttershy() {
    Column(
        Modifier.fillMaxSize().padding(2.cssRem),
        verticalArrangement = Arrangement.Bottom,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        val colorMode = ColorMode.current
        val invertLevel = if (colorMode.isLight) 1 else 0
        Image(
            "/fluttershy.png",
            "Fluttershy",
            Modifier
                .height(10.cssRem)
                .filter(invert(invertLevel))
                .animation(FluttershyKeyframes.toAnimation(
                    duration = 10.s,
                    timingFunction = AnimationTimingFunction.Linear,
                    iterationCount = AnimationIterationCount.Infinite,
                )),
            ref = ref { element ->
                element.onclick = {
                    val audio = Audio("yippee.mp3")
                    audio.play()
                }
            },
        )
    }
}

val FluttershyKeyframes = Keyframes {
    from { Modifier.translateX(1000.percent) }
    to { Modifier.translateX((-1000).percent) }
}

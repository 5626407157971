package io.github.shaksternano.albert21.components

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import kotlinx.browser.document
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.px

@Composable
fun AlbertCursor() {
    val colorMode = ColorMode.current
    val invertLevel = if (colorMode.isLight) 1 else 0
    var cursorX by remember { mutableStateOf(-100) }
    var cursorY by remember { mutableStateOf(-100) }
    document.body?.onpointermove = { event ->
        cursorX = event.clientX
        cursorY = event.clientY
        event
    }
    Image(
        "/albert.png",
        "Albert",
        Modifier
            .position(Position.Relative)
            .maxHeight(2.cssRem)
            .filter(invert(invertLevel))
            .transform {
                scaleX(-1)
            }
            .left(cursorX.px - 2.cssRem)
            .top(cursorY.px - 0.5.cssRem),
    )
}

package io.github.shaksternano.albert21.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.functions.invert
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.filter
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import org.jetbrains.compose.web.css.cssRem

@Composable
fun ConfettiImage(flip: Boolean = false) {
    val colorMode = ColorMode.current
    val invertLevel = if (colorMode.isLight) 1 else 0
    Image(
        "/confetti.png",
        "Confetti",
        Modifier
            .maxHeight(20.cssRem)
            .filter(invert(invertLevel))
            .transform {
                if (flip) {
                    scaleX(-1)
                }
            },
    )
}
